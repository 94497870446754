import dotenv from 'dotenv';
import flat from 'flat';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import { countryByLocale, VALID_LOCALES } from './locale';

global.envVars = {};

function buildLocaleEnv(locale) {
  if (typeof __CLIENT_ENV__ === 'undefined') {
    const localeEnv = cloneDeep(process.env);

    let countryCode = 'US';
    if (countryByLocale) {
      countryCode = countryByLocale(locale);
    }

    localeEnv.CURRENT_LOCALE = locale;

    switch (countryCode) {
      case 'CA':
        localeEnv.GOOGLE_TAG_MANAGER_KEY = localeEnv.GOOGLE_TAG_MANAGER_KEY_CA;
        localeEnv.COOKIE_DOMAIN = localeEnv.COOKIE_DOMAIN_CA;
        break;
      default:
        break;
    }
    return localeEnv;
  }

  return {};
}

function initializeLocaleEnvs() {
  const locales = flat(VALID_LOCALES);
  Object.keys(locales).forEach((k) => { global.envVars[locales[k]] = buildLocaleEnv(locales[k]); });
}

export default function env({ locale = 'en-us' } = { locale: 'en-us' }) {
  if (typeof __CLIENT_ENV__ === 'undefined') {
    // User REDIS_PORT as a shortcut to see if the env file has been loaded
    if (!process.env.REDIS_HOST || !get(global.envVars, [locale, 'REDIS_HOST'], null)) {
      dotenv.config({ path: '../.env' });
      initializeLocaleEnvs();
    }
    return get(global.envVars, locale, {});
  }
  return window.__CLIENT_ENV__;
}

export const isProduction = () => (
  env().IS_PRODUCTION === true || env().IS_PRODUCTION === 'true'
);

export const isAssetIntegrityEnabled = () => (
  env().ENABLE_ASSET_INTEGRITY === true || env().ENABLE_ASSET_INTEGRITY === 'true'
);

// We set INVALIDATE_CACHED_ASSETS to false for local dev.
export const invalidateCachedAssets = () => (
  env().INVALIDATE_CACHED_ASSETS === true || env().INVALIDATE_CACHED_ASSETS === 'true'
);

export const clientEnv = ({ locale = 'en-us' } = { locale: 'en-us' }) => {
  const {
    ADMIN_HOST,
    AIRBRAKE_CLIENT_ID,
    AIRBRAKE_CLIENT_KEY,
    ASSET_CDN_HOST,
    BASE_V3_1_API_URL,
    CDN_HOST,
    CDN_HTTPS_HOST,
    CONCUR_APP_CENTER_LINK,
    COOKIE_DOMAIN,
    CURRENT_LOCALE,
    ENABLE_ROKT,
    ENABLE_UPEXPRESS_BANNER,
    ENTERPRISE_HOST,
    ENVIRONMENT,
    EXPERIMENTS_BASE_URL,
    FORD_AFFILIATE_ID,
    GIT_HASH,
    GOOGLE_API_BROWSER_KEY,
    GOOGLE_CONVERSION_PARTNER_ID,
    GOOGLE_CONVERSION_URL,
    GOOGLE_OAUTH_ID,
    GOOGLE_RECAPTCHA_SITE_KEY,
    GOOGLE_STATIC_MAPS_ID,
    GOOGLE_TAG_MANAGER_KEY,
    INSIGHTS_KEY,
    INVALIDATE_CACHED_ASSETS,
    IS_PRODUCTION,
    MAPBOX_STYLE,
    MAPBOX_TOKEN,
    MIXPANEL_API_KEY,
    NODE_BASE_BP_URL,
    NODE_BASE_ENTERPRISE_API_URL,
    NODE_BASE_INTERNAL_API_URL,
    NODE_BASE_V3_1_API_URL,
    NODE_BASE_V4_API_URL,
    NODE_PW_URL,
    ONELINK_ID,
    PORTAL_URL,
    PW_URL,
    RS_TENANT_ALIAS,
    SECURE_COOKIES,
    STAPLES_AFFILIATE_ID,
    TICKETMASTER_PARTNER_ID,
    UNBOUNCE_ID,
    V3_API_CLIENT_ID,
    WIDGET_BASE_URL,
  } = env({ locale });

  return {
    ADMIN_HOST,
    AIRBRAKE_CLIENT_ID,
    AIRBRAKE_CLIENT_KEY,
    ASSET_CDN_HOST,
    BASE_V3_1_API_URL,
    CDN_HOST,
    CDN_HTTPS_HOST,
    CONCUR_APP_CENTER_LINK,
    COOKIE_DOMAIN,
    CURRENT_LOCALE,
    ENABLE_ROKT,
    ENABLE_UPEXPRESS_BANNER,
    ENTERPRISE_HOST,
    ENVIRONMENT,
    EXPERIMENTS_BASE_URL,
    FORD_AFFILIATE_ID,
    GIT_HASH,
    GOOGLE_API_BROWSER_KEY,
    GOOGLE_CONVERSION_PARTNER_ID,
    GOOGLE_CONVERSION_URL,
    GOOGLE_OAUTH_ID,
    GOOGLE_RECAPTCHA_SITE_KEY,
    GOOGLE_STATIC_MAPS_ID,
    GOOGLE_TAG_MANAGER_KEY,
    INSIGHTS_KEY,
    INVALIDATE_CACHED_ASSETS,
    IS_PRODUCTION,
    MAPBOX_STYLE,
    MAPBOX_TOKEN,
    MIXPANEL_API_KEY,
    NODE_BASE_BP_URL,
    NODE_BASE_ENTERPRISE_API_URL,
    NODE_BASE_INTERNAL_API_URL,
    NODE_BASE_V3_1_API_URL,
    NODE_BASE_V4_API_URL,
    NODE_PW_URL,
    ONELINK_ID,
    PORTAL_URL,
    PW_URL,
    RS_TENANT_ALIAS,
    SECURE_COOKIES,
    STAPLES_AFFILIATE_ID,
    TICKETMASTER_PARTNER_ID,
    UNBOUNCE_ID,
    V3_API_CLIENT_ID,
    WIDGET_BASE_URL,
  };
};
