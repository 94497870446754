// External libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Measure from 'react-measure';
import { Map } from 'immutable';
import { canUseDOM } from 'exenv';
import get from 'lodash/get';

// Internal libraries
import { locationUnavailableMessage } from 'lib/common/messages';
import { PAGEVIEW_EVENT } from 'lib/analytics/events/index';
import { urlProps } from 'lib/analytics/page-properties';

import Search from 'models/search';
import Quote from 'models/quote';
import { Location } from 'models/locations';

// Components
import AmenityList from 'components/common/amenity-list';
import PackageEvents from 'components/search/list/package-events';
import FriendlyLocationDistance from 'components/location/friendly-distance';
import LocationImage from 'components/location/image';
import LocationImageGallery from 'components/location/image/gallery';
import ValidationSteps from 'components/common/validation-steps';
import OperatingHours from 'components/search/list/location-detail/operating-hours';
import About from 'components/search/list/location-detail/about';
import LocationRating from 'components/common/location-rating';
import CurrencySymbol from 'components/common/currency-symbol';

import changeSelectedLocation from 'action-creators/search/change-selected-location';
import trackEventCreator from 'action-creators/analytics/track-event';
import addMessage from 'action-creators/messaging/add-message';
import getLocationSellerCreator from 'action-creators/search/get-location-seller';

import { ExperimentsConsumer } from 'providers/experiments-provider';

const propTypes = {
  toggleMap: PropTypes.func,
  sellers: PropTypes.instanceOf(Map),
  shouldRenderBasicInfo: PropTypes.bool.isRequired,
  currentSearch: PropTypes.instanceOf(Search).isRequired,
  location: PropTypes.instanceOf(Location).isRequired,
  quote: PropTypes.instanceOf(Quote),
  clientSettings: PropTypes.instanceOf(Map),
  imageDisplayType: PropTypes.string,
  displayMap: PropTypes.bool,
  trackEvent: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  getLocationSeller: PropTypes.func.isRequired,
  changeLocation: PropTypes.func.isRequired,
  isCheckoutSurprise: PropTypes.bool,
};

const defaultProps = {
  shouldRenderBasicInfo: true,
  imageDisplayType: 'gallery',
  toggleMap: () => {},
  sellers: new Map(),
  quote: null,
  isCheckoutSurprise: false,
  clientSettings: Map(),
  displayMap: false,
};

class LocationDetail extends Component {
  constructor(props) {
    super(props);

    this.back = this.back.bind(this);
    this.toggleMap = this.toggleMap.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.onResize = this.onResize.bind(this);

    if (this.props.scrollToTop) {
      this.scrollToTop = this.props.scrollToTop;
    } else {
      this.scrollToTop = () => {};
    }

    this.state = {
      listWidth: null,
      isPopoverShown: false,
    };
  }

  componentWillMount() {
    if (canUseDOM) {
      this.scrollToTop();
      const { currentSearch, displayMap, quote, trackEvent } = this.props;
      if (currentSearch.selectedLocationId) {
        trackEvent({
          ...PAGEVIEW_EVENT,
          properties: {
            ...urlProps(),
            LocationID: currentSearch.selectedLocationId,
            MapListView: displayMap ? 'MapView' : 'ListView',
            QuoteID: get(quote, 'id', null),
          },
        });
      }
    }
  }

  componentDidMount() {
    const { quote } = this;
    if (!quote) {
      this.props.addMessage(locationUnavailableMessage);
    }

    this.fetchLocationSeller(this.props.location);

    if (this.scrollToTop) {
      this.scrollToTop();
    }
  }

  componentWillUpdate(nextProps) {
    const { trackEvent, currentSearch, displayMap } = nextProps;
    if (currentSearch.selectedLocationId != this.props.currentSearch.selectedLocationId) {
      trackEvent({
        ...PAGEVIEW_EVENT,
        properties: {
          ...urlProps(),
          LocationID: currentSearch.selectedLocationId,
          MapListView: displayMap ? 'MapView' : 'ListView',
        },
      });
    }
  }

  componentDidUpdate() {
    this.fetchLocationSeller(this.props.location);
  }

  componentWillUnmount() {
    if (this.scrollToTop) {
      this.scrollToTop();
    }
  }

  componentDidCatch(error, info) {
    console.warn(error, info);
  }

  fetchLocationSeller(location) {
    const { sellers, getLocationSeller } = this.props;

    if (location && location.sellerId && !sellers.get(location.sellerId.toString())) {
      getLocationSeller({ locationId: location.id });
    }
  }

  back(event) {
    event.stopPropagation();
    event.preventDefault();
    this.props.changeLocation();
  }

  toggleMap(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.scrollToTop) {
      this.scrollToTop();
    }
    this.props.toggleMap(e);
  }

  get quote() {
    if (this.props.quote) {
      return this.props.quote;
    }

    return this.props.location ? this.props.location.getQuote() : null;
  }

  renderValidationStep(step, index) {
    return (
      <div className="col-xs-12 col-md-4 col-centered validation-block" key={index}>
        <div className="validation-number">{index + 1}.</div>
        <div className="validation-icon" style={{ backgroundImage: `url(${step.icon})` }} />
        <p>{step.instructions}</p>
      </div>
    );
  }

  renderValidationSteps() {
    const { quote } = this;
    if (!quote || !quote.validation) { return null; }
    const validationSteps = quote.validation.steps;

    const collection = validationSteps.map((step, index) => this.renderValidationStep(step, index));

    return collection.toArray();
  }

  // TODO Figure out why this function is called when the quote is null
  renderPrice() {
    const { quote } = this;
    const { clientSettings, isCheckoutSurprise, location } = this.props;

    const price = quote ? quote.getListingPrice(isCheckoutSurprise, location, clientSettings).toString() : null;

    if (price && price !== 'NaN') {
      const formattedPrice = price.split('.');
      const longPrice = (price.length > 5);

      return (
        <p className={longPrice ? 'long-price price align-right' : 'price align-right'}>
          <sup>
            <CurrencySymbol location={location} />
          </sup>
          { formattedPrice[0] }
          { formattedPrice.length === 2 ? <sup>{ formattedPrice[1] }</sup> : null }
        </p>
      );
    }

    return null;
  }

  renderBasicInfo() {
    const { location, shouldRenderBasicInfo } = this.props;
    if (!shouldRenderBasicInfo) { return null; }
    const { quote } = this;

    let quoteName = null;
    if (this.props.currentSearch.isPackageSearch) {
      quoteName = <h4 className="h6 with-sub light wrap-ellipses top">{quote.name}</h4>;
    }
    const addressClass = `h6 sub with-sub wrap-ellipses${quoteName ? ' sub' : ' top'}`;
    const areaClass = (this.props.currentSearch.isDailySearch || this.props.currentSearch.isEventSearch ? 'area' : 'area to-bottom');

    return (
      <div className={`${areaClass} sub-nav-border`}>
        <div className="row">
          <div className="col-xs-8">
            {quoteName}
            <h2 className={addressClass}>
              { location.address }
            </h2>
            <h3 className="h6 sub wrap-ellipses">
              { location.name }
            </h3>
          </div>
          <div className="col-xs-4">
            { this.renderPrice() }
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <div className="distance-info">
              <FriendlyLocationDistance location={location} />
            </div>
          </div>
          <div className="col-xs-6 text-right visible-xs">
            <a className="text-size-10 text-weight-light btn-style-minimal-outline btn-color-link-blue" onClick={this.toggleMap}>
              VIEW IN MAP
            </a>
          </div>
        </div>
      </div>
    );
  }

  updateDimensions(dimensions) {
    this.setState({ listWidth: dimensions.width });
  }

  onResize(contentRect) {
    if (get(contentRect, 'bounds', false)) {
      this.setState({ dimensions: contentRect.bounds });
    }
  }

  renderLocationImage() {
    const { imageDisplayType, location, currentSearch, trackEvent } = this.props;

    if (imageDisplayType !== 'gallery') {
      return (
        <LocationImage location={location} type="gallery">
          <div className="text-center">
            {this.props.location.address}
            <br />
            <span className="weak">
              {this.props.location.name}
            </span>
          </div>
        </LocationImage>
      );
    }

    const maxHeight = 250;
    const sellerId = location.sellerId ? location.sellerId.toString() : '';
    const seller = this.props.sellers.get(sellerId);

    let listWidth;
    if (this.state && this.state.listWidth) {
      listWidth = this.state.listWidth;
    }

    return (
      <LocationImageGallery
        location={location}
        currentSearch={currentSearch}
        seller={seller}
        maxHeight={maxHeight}
        width={listWidth}
        app="Location Details"
        trackEvent={trackEvent}
      />
    );
  }

  renderMonthlyDescription() {
    if (!this.props.currentSearch.isMonthlySearch) {
      return null;
    }

    const { quote } = this;
    if (!quote) {
      return null;
    }

    return (
      <div className="area">
        <h3 className="h5 top">Monthly Fee Details:</h3>
        <div className="info-list">{quote.description}</div>
      </div>
    );
  }

  render() {
    const location = this.props.location || {};
    const { quote } = this;
    const { validAmenities } = quote || {};

    if (!location) { return <section />; }

    let content;

    if (!(quote && quote.amenities)) {
      content = (
        <div>
          { this.renderBasicInfo() }
          { this.renderLocationImage() }
        </div>
      );
    } else {
      content = (
        <div>
          { this.renderBasicInfo() }
          <div className="hidden-xs">
            <PackageEvents events={quote.get('packagePricings')} />
          </div>
          <div className="area">
            <About location={location} quote={quote} />
            <OperatingHours location={location} />
          </div>
          { this.renderLocationImage() }
          <AmenityList
            amenities={validAmenities}
            amenityClassName="margin-top-10 col-xs-3"
            className="area amenity-list"
          />
          <div className="visible-xs">
            <PackageEvents events={quote.get('packagePricings')} />
          </div>
          <div className="area zebra-dark">
            <LocationRating rating={location.hasRatings() ? location.ratingSummary.averageRating.toFixed(1) : null} />
          </div>
          <div className="area room-for-button">
            { quote.validation ? (<h3 className="h5 text-center top">How to Park</h3>) : null }
            <ValidationSteps
              validation={quote.validation}
              className="text-color-dark-slate text-size-12 text-align-xs-center text-align-sm-left"
            />
          </div>
        </div>
      );
    }

    return (
      <Measure bounds onResize={this.onResize}>
        {({ measureRef }) => (
          <div className="location-detail container" ref={measureRef}>{content}</div>
        )}
      </Measure>
    );
  }
}

LocationDetail.propTypes = propTypes;
LocationDetail.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const {
    selectedLocation,
    currentSearch,
    event,
    sellers,
  } = state.search;

  const {
    clientSettings
  } = state.app;

  const {
    startTime,
    endTime,
  } = currentSearch;

  return {
    location: selectedLocation,
    currentSearch,
    startTime,
    clientSettings,
    endTime,
    event,
    sellers,
  };
};


const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeSelectedLocation,
    addMessage,
    getLocationSeller: getLocationSellerCreator,
    trackEvent: trackEventCreator,
  }, dispatch)
);

const LocationDetailWrapper = props => (
  <ExperimentsConsumer>
    <LocationDetail {...props} />
  </ExperimentsConsumer>
);

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetailWrapper);
