import env from 'lib/env';

function fetchCaptchaToken(action) {
  return new Promise((resolve, reject) => {
    const { GOOGLE_RECAPTCHA_SITE_KEY } = env();

    if (window && window.grecaptcha && window.grecaptcha.enterprise) {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise.execute(GOOGLE_RECAPTCHA_SITE_KEY, { action })
          .then((token) => {
            resolve(token);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } else {
      reject(new Error('Recaptcha script not available.'));
    }
  });
}

export default fetchCaptchaToken;
